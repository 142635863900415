import React from 'react';
import GridLayout from 'react-grid-layout';
import _ from 'lodash';
import './index.css';

import '../node_modules/react-grid-layout/css/styles.css';

class Body extends React.Component {
  
  constructor(){
    super();
  
    this.state = {
      showBtn1: true,
      showBtn2: false,
      layouts: [
        {i: 'a', x: 0, y: 0, w: 2, h: 13, static: true}
      ],
      cols : 12,
      rowHeight : 30,
      width : window.innerWidth,
      player: [],
      hideLoader: true,
    };
  }

  componentDidMount() {
    this.setState({showBtn2: false})
  }

  componentWillUnmount() { 
  }

  openPage = (url) => {
    this.setState({layouts: this.state.layouts.concat(
      {i: 'b', x: 2, y: 0, w: 8, h: 16, minW: 2, maxW: 25}
    ) })
    this.setState({hideLoader: false})
    this.setState({player: <iframe
      title="Chime Player"
      src={url}
      allow="camera; microphone; display-capture"
      width="99%"
      height="99%"
      />
    })
  }

  removePage = (e) => {
    this.setState({layouts: _.reject(this.state.layouts, {i: 'b'}) })
    this.setState({player: ''})
    this.setState({hideLoader: true})
  }

  render() {
    const borderStyle = {
      background: 'silver',
      border: '1px solid black'
    };
    const loadingStyle = {
      width: "100%",
      height: "100%",
      zIndex: 0,
      top: "50%",
      textAlign: "center",
      position: "absolute"
    };
    const removeStyle = {
      position: "absolute",
      right: "2px",
      top: 0,
      cursor: "pointer",
      zIndex: 2
    };
    const playerStyle = {
      width: "99%",
      height: "99%",
      zIndex: 1,
      position: "absolute"
    }
    return (
    <div className="Body">
      <GridLayout className="layout" layout={this.state.layouts} cols={this.state.cols} rowHeight={this.state.rowHeight} width={this.state.width}>
        <div style={borderStyle} key="a">
          Chime Demo
          <div>
            <a href="/#" onClick={() => this.openPage('https://chimechecker.aws.tkoba.tv/')}>Readiness Checker (newest - 20210601)</a> <a href="https://chimechecker.aws.tkoba.tv/" target="_blank">^</a><br />
            <a href="/#" onClick={() => this.openPage('https://chimesdk.aws.tkoba.tv/')}>Chime SDK Demo (newest - 20210804)</a> <a href="https://chimesdk.aws.tkoba.tv/" target="_blank">^</a><br />
            <a href="/#" onClick={() => this.openPage('https://09s8qoaoo1.execute-api.ap-northeast-1.amazonaws.com/Prod/')}>React Component Demo (newest - 20211216)</a> <a href="https://09s8qoaoo1.execute-api.ap-northeast-1.amazonaws.com/Prod/" target="_blank">^</a><br />    
          </div>
          <br /><br />

          <a href="/#" onClick={() => this.openPage('https://ngx362gjfc.execute-api.ap-northeast-1.amazonaws.com/Prod/')}>blur demo (v2.7)</a> <a ref="https://ngx362gjfc.execute-api.ap-northeast-1.amazonaws.com/Prod/" target="_blank">^</a><br />
          <a href="/#" onClick={() => this.openPage('https://row2bwgzee.execute-api.ap-northeast-1.amazonaws.com/Prod/')}>live transcribe demo</a> <a href="https://row2bwgzee.execute-api.ap-northeast-1.amazonaws.com/Prod/" target="_blank">^</a><br />
          <a href="/#" onClick={() => this.openPage('https://44g9ww7ls7.execute-api.ap-northeast-1.amazonaws.com/Prod/')}>live transcribe w/ translate demo</a> <a href="https://44g9ww7ls7.execute-api.ap-northeast-1.amazonaws.com/Prod/" target="_blank">^</a><br />
          <a href="/#" onClick={() => this.openPage('https://5xdx3drnph.execute-api.ap-northeast-1.amazonaws.com/Prod/')}>auto-detect live transcribe w/ translate demo</a> <a href="https://5xdx3drnph.execute-api.ap-northeast-1.amazonaws.com/Prod/" target="_blank">^</a><br />
          
          <a href="/#" onClick={() => this.openPage('https://165ewkunj9.execute-api.ap-northeast-1.amazonaws.com/Prod/')}>media capture w/ facial rekognition</a> <a href="https://165ewkunj9.execute-api.ap-northeast-1.amazonaws.com/Prod/" target="_blank">^</a><br />
          <a href="/#" onClick={() => this.openPage('https://demo3.aws.tkoba.tv/')}>ducking audio demo</a> <a href="https://demo3.aws.tkoba.tv/" target="_blank">^</a> <a href="https://aws.amazon.com/blogs/business-productivity/transforming-audio-and-shared-content-in-the-amazon-chime-sdk-for-javascript/" target="_blank" rel="noopener noreferrer">src</a><br/>
          <a href="https://ab3.aws.tkoba.tv/chat.html">AB - training service</a><br />
          <a href="/#" onClick={() => this.openPage('https://production.dy59vp0gm5wtc.amplifyapp.com/')}>Gym - connect escalation with chime</a> <a href="https://production.dy59vp0gm5wtc.amplifyapp.com/" target="_blank">^</a><br />
          <a href="/#" onClick={() => this.openPage('https://f-backendstack-tkoba-bucket.s3-ap-northeast-1.amazonaws.com/index.html')}>Flect - demo</a> <a href="https://f-backendstack-tkoba-bucket.s3-ap-northeast-1.amazonaws.com/index.html" target="_blank">^</a> <a href="https://github.com/w-okada/flect-chime-sdk-demo" target="_blank" rel="noopener noreferrer">src</a><br />
          <a href="/#" onClick={() => this.openPage('https://165ewkunj9.execute-api.ap-northeast-1.amazonaws.com/Prod/')}>ML monitor & detect</a> <a href="https://165ewkunj9.execute-api.ap-northeast-1.amazonaws.com/Prod/" target="_blank">^</a><br />    
          <a href="/#" onClick={() => this.openPage('https://yc4b9407x2.execute-api.ap-northeast-1.amazonaws.com/Prod/')}>Blur (20210930)</a> <a href="https://yc4b9407x2.execute-api.ap-northeast-1.amazonaws.com/Prod/" target="_blank">^</a><br />
          <a href="/#" onClick={() => this.openPage('https://8qbc6eh4b5.execute-api.ap-northeast-1.amazonaws.com/Prod/')}>media capture (individual) demo (20211006) </a> <a href="https://8qbc6eh4b5.execute-api.ap-northeast-1.amazonaws.com/Prod/" target="_blank">^</a><br />

          <div style={{padding: "20px 0px"}}>
            in development<br/>
            <a href="/#" onClick={() => this.openPage('https://ahy61cfwwc.execute-api.ap-northeast-1.amazonaws.com/Prod/')}>react alice carousel demo</a><br />
            <a href="/#" onClick={() => this.openPage('https://pvvd9d8y56.execute-api.ap-northeast-1.amazonaws.com/Prod/')}>avatar demo (v2.8)</a><br />
            <a href="/#" onClick={() => this.openPage('https://production.d30n2b3561cqqs.amplifyapp.com/')}>escalation demo (amplify)</a><br />
            <a href="/#" onClick={() => this.openPage('https://070koepl7l.execute-api.ap-northeast-1.amazonaws.com/Prod/')}>chime bridge chime sdk</a><br />
            <a href="https://d28fvl59bhgn3g.cloudfront.net/vr/sender.html" target="_blank">VR demo - sender</a><br />
            <a href="https://d28fvl59bhgn3g.cloudfront.net/vr/chime-room.html?externalMeetingId=" target="_blank">VR demo - receiver</a><br />
          </div>
        </div>
        <div style={borderStyle} key="b" hidden={this.state.hideLoader}>
          <div style={playerStyle}>{this.state.player}</div>
          <div style={loadingStyle} hidden={this.state.hideLoader}>Loading...</div>
          <span className="remove" style={removeStyle} onClick={(e) => this.removePage(e)}>x</span>          
        </div>
      </GridLayout>
    </div>
  )
  }
}

export default Body;
