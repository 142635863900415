import React from 'react';
import './index.css';

class Footer extends React.Component {

  render() {
    return (
      <center>
      <span className="foot">
        <a href={"https://aws.amazon.com/what-is-cloud-computing"}>
          <img src={"https://d0.awsstatic.com/logos/powered-by-aws.png"} alt="Powered by AWS Cloud Computing" height="30" width="80"/>
        </a>
      </span>
      <hr width="600"/>
      <span className="foot">
        All activities may be logged or monitored.<br/>
        Contents of tkoba.tv are provided "as is" without warranty of any kind whatsoever.<br/>
        tkoba.tv, and the author thereof, shall not be held responsible or liable, under any circumstances,<br/>
        for any damages resulting from the use or inability to use the information within.
      </span>
      </center>
    )
  }
}

export default Footer;
